@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    background-color: #111111;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

.navigation {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    z-index: 4;
    background-image: linear-gradient(180deg, rgb(17, 17, 17) 15%, transparent);
}

.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.menu li {
    padding: 20px;
}

.logo {
    height: 33px;
    z-index: 3;
}

.logo img {
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.menu li a {
    color: #ffffffcc;
}

.hire-me {
    color: #46a0f5;
    z-index: 3;
}

.hire-me i {
    margin-left: 4px;
}

.menu li a:hover {
    color: #46a0f5;
    transition: all ease 0.5s;
}

.hire-me:hover {
    color: #1a82e2;
    transition: all ease 0.5s;
}

#main {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

#main::before {
    content: "";
    position: absolute;
    width: 130%;
    height: 100vh;
    top: -23%;
    left: -20%;
    transform: rotate(-15deg);
    background: linear-gradient(45deg, rgb(19, 19, 19) -10%, #141516);
    z-index: -1;
}

.main-text {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-15%, -50%);
    width: 500px;
}

.main-text h1 {
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 600;
    text-shadow: 2px 2px 30px rgba(0, 0, 0, 0.01);
    margin: 0px;
    line-height: 45px;
}

.main-text p {
    color: #8a94a7c0;
    font-size: 1rem;
    margin: 0;
    margin-top: 10px;
}

.menu-icon,
.menu-btn {
    display: none;
}

.main-btns {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.main-btns a {
    width: 180px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.05);
    letter-spacing: 1px;
    font-size: 0.9rem;
    border-radius: 1.5rem;
}

.download-cv-btn {
    background: linear-gradient(90deg, #2788e2 20%, #3b58b8);
    margin-right: 20px;
}

.hire-me-btn {
    background-color: #242830;
}

.hire-me-btn:hover {
    background-color: #0f7ce2;
    transition: all ease 0.3s;
}

.model {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
    right: 7%;
    transform: translate(-7%, -50%);
}

.model img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.model-bg {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 45%;
    height: 100%;
}

.model-bg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
}

/* Skills */
#skills {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto auto 50px auto;
}

.skills-text-section {
    width: 400px;
}

.skill-heading span {
    color: #416cdf;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
}

.skill-heading h2 {
    color: #ffffff;
    font-size: 3.3rem;
    margin: 0px;
    font-weight: 400;
    letter-spacing: 1px;
}

.s-box-container {
    margin-top: 30px;
}

.skill-box {
    display: flex;
    margin: 25px 0px;
}

.s-box-icon {
    margin-right: 12px;
}

.webpack{
    width: 40px;
    height: 40px;
    background: #202020;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
}

.s-box-icon i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f011;
    font-size: 1.1rem;
}

.skill-box strong {
    color: #ffffff;
    margin: 0px;
    font-weight: 400;
    font-size: 0.9rem;
}

.skill-box p {
    color: #8a94a783;
    font-size: 0.9rem;
}

.fa-html5 {
    color: #e06061;
}

.fa-css3-alt {
    color: #5d85f3;
}

.fa-js {
    color: #f9ca31;
}

.progress-line {
    position: relative;
    height: 5px;
    width: 100%;
    background-color: #1d1d1d;
    border-radius: 10px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.05),
        0px 1px rgba(54, 54, 54, 0.8);
}

.progress-line span {
    height: 100%;
    background: linear-gradient(90deg, #2788e2 20%, #3b43b8);
    position: absolute;
    border-radius: 10px;
}

.progress-line.html span {
    width: 90%;
}

.progress-line.css span {
    width: 85%;
}

.progress-line.js span {
    width: 80%;
}

.progress-line.webpack_class span {
    width: 69%;
}

.progress-line.git span {
    width: 60%;
}



.progress-line span::before {
    position: absolute;
    content: "";
    right: 0px;
    top: -10px;
    height: 0px;
    width: 0px;
    border: 7px solid transparent;
    border-bottom-width: 0px;
    border-right-width: 0px;
    border-top-color: rgb(20, 98, 243);
}

.progress-line span::after {
    position: absolute;
    right: 0px;
    top: -28px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    background-color: rgb(20, 98, 243);
    padding: 1px 8px;
    border-radius: 3px;
}

.progress-line.html span::after {
    content: "90%";
}

.progress-line.css span::after {
    content: "85%";
}

.progress-line.js span::after {
    content: "80%";
}

.progress-line.webpack_class span::after {
    content: "69%";
}

.progress-line.git span::after {
    content: "60%";
}

.skill-img {
    width: 600px;
    height: 600px;
}

.skill-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.intro-heading {
    width: 60%;
}

.intro-heading strong {
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 600;
}

.intro-heading p {
    color: #8a94a763;
}

.intro-video {
    margin-top: 50px;
}

.intro-video iframe {
    width: 712px;
    height: 400px;
}

#services {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;
    position: relative;
}

.services-heading {
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 90%;
    background-color: #141516;
    padding: 10px;
    display: flex;
}

.services-box-container {
    width: 90%;
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
}

.service-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px 20px 20px;
}

.service-box i {
    font-size: 2.5rem;
    background: linear-gradient(90deg, #2788e2 20%, #3b67b8);
    margin-bottom: 10px;
    margin-right: auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.service-box p {
    color: #8a94a783;
    font-size: 0.95rem;
}

.service-box strong {
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 1px;
    color: #e4e4e4;
}

#get-in-touch {
    width: 90%;
    height: 100px;
    background-color: #15181d;
    display: flex;
    margin: 60px auto;
    padding: 20px 40px;
    justify-content: space-between;
    align-items: center;
    background-image: url(/static/media/bg-2.a17ed489.png);
    background-position: top right;
    background-size: 400px;
    background-repeat: no-repeat;
}

#get-in-touch span {
    color: #e4e4e4;
    font-size: 1.3rem;
    font-weight: 600;
}

#get-in-touch a {
    color: #fff;
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #2788e2 20%, #3b4eb8);
    border-radius: 1.5rem;
}

#contact {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#contact form {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#contact form input,
#contact form textarea {
    width: 100%;
    height: 50px;
    margin: 5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #373a412d;
    color: #ffffff;
    border-radius: 5px;
}

#contact form textarea {
    height: 150px;
}

#contact form input[type="submit"] {
    height: 45px;
    background: linear-gradient(90deg, #2065cc 20%, #3b58b8);
    color: #ffffff;
    text-transform: uppercase;
}

footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #14151610;
    padding: 10px;
    margin-top: 50px;
    border-top: 1px solid #272729;
}

.footer-social {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-social a {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #ffffff;
    background-color: #292b3252;
    margin: 5px;
    font-size: 0.9rem;
    font-weight: 400;
}

.footer-social a:hover {
    background-color: #2993f7;
    transition: all ease 0.5s;
}

::selection {
    color: #e4e4e4;
    background-color: #416cdf;
}

#services,
#skills {
    padding-top: 60px;
}

@media (max-width: 1200px) {
    .model-bg {
        display: none;
    }

    .model {
        width: 400px;
        height: 400px;
        right: 5%;
        transform: translate(-5%, -50%);
    }

    .main-text {
        left: 10%;
        transform: translate(-10%, -50%);
    }
}
@media (max-width: 1000px) {
    .navigation {
        justify-content: space-between;
        height: 65px;
        padding: 35px 25px;
    }

    .navigation .menu {
        display: none;
        position: absolute;
        top: 65px;
        left: 0px;
        background-color: #111111;
        border-bottom: 4px solid #1b1b1b;
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    .navigation .menu li {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    .navigation .menu li a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 30px 20px;
        border: 1px solid rgba(38, 38, 38, 0.03);
    }

    .navigation .menu li a:hover {
        color: #ffffff;
        background-color: #2065cc;
    }

    .menu-icon {
        display: block;
    }

    nav .menu-icon {
        cursor: pointer;
        float: right;
        padding: 5px;
        position: relative;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        z-index: 2;
    }

    nav .menu-icon .nav-icon {
        background-color: #ffffffcc;
        display: block;
        position: relative;
        height: 2px;
        transition: background 0.2s ease-out;
        width: 25px;
    }

    nav .menu-icon .nav-icon:before,
    nav .menu-icon .nav-icon:after {
        background-color: #ffffffcc;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        transition: all ease-out 0.2s;
        width: 100%;
    }
    nav .menu-icon .nav-icon:before {
        top: 5px;
    }
    nav .menu-icon .nav-icon:after {
        top: -5px;
    }

    nav .menu-icon .nav-icon,
    nav .menu-icon:checked ~ .menu-icon .nav-icon {
        background-color: transparent;
    }

    nav .menu-btn:checked ~ .menu-icon .nav-icon:before {
        transform: rotate(-45deg);
        top: 0px;
    }

    nav .menu-btn:checked ~ .menu-icon .nav-icon:after {
        transform: rotate(45deg);
        top: 0px;
    }

    nav .menu-btn:checked ~ .menu {
        display: block;
    }

    .menu::before {
        position: absolute;
        left: 0px;
        top: -60px;
        content: "";
        width: 100%;
        height: 60px;
        background-color: #111111;
    }

    .model {
        display: none;
    }

    .main-text {
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #skills {
        width: 100%;
        justify-content: space-between;
        padding-left: 40px;
    }

    .skills-text-section {
        width: 400px;
    }

    .skill-img {
        width: 40%;
        height: 300px;
    }

    .skill-img img {
        object-fit: cover;
        object-position: left;
    }
}
@media (max-width: 900px) {
    .intro-video iframe {
        width: 700px;
        height: 400px;
    }

    .services-box-container {
        grid-template-rows: auto;
        grid-template-columns: 1fr;
    }
}
@media (max-width: 800px) {
    .intro-video {
        width: 100%;
    }

    .intro-video iframe {
        width: 100%;
        height: 38vh;
    }

    .intro-heading {
        width: 80%;
    }
}
@media (max-width: 620px) {
    #contact form {
        width: 90%;
    }

    .main-text {
        width: 85%;
    }

    .skill-heading h2 {
        font-size: 2.2rem;
    }

    .intro-video iframe {
        width: 100%;
        height: 200px;
    }
}
@media (max-width: 530px) {
    .skill-img {
        display: none;
    }

    .skills-text-section {
        width: 85%;
    }

    .main-btns {
        flex-direction: column;
    }

    .main-btns a {
        width: 100%;
        margin: 5px 0px;
    }

    .intro-heading {
        width: 100%;
    }

    .intro-heading strong {
        font-size: 2rem;
    }

    #get-in-touch {
        padding: 20px 10px;
        text-align: center;
    }

    #get-in-touch span {
        font-size: 1.1rem;
    }

    .copyright {
        font-size: 0.8rem;
    }

    .intro-video iframe {
        height: auto;
    }
}
@media (max-width: 300px) {
    .navigation {
        padding: 30px 10px;
    }
}

